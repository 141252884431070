body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

 background: #fafafa!important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
@import url('https://fonts.googleapis.com/css2?family=Shizuru&display=swap');

.slide-form{
  background: url(../public/images/wbb.png);
  background-size: cover;
  background-position: top;
  background-repeat: no-repeat;
  box-sizing: border-box;
  position: relative;
  padding-top: 90px;
  padding-bottom: 90px;
}
.slide-form h1{
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  font-weight: bolder;
}
#slider-container{
  background: rgba(214, 45, 45, 0.5);
  
}
.card {
  /* Add shadows to create the "card" effect */
  box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  transition: 0.3s;
}

/* On mouse-over, add a deeper shadow */
.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0,0,0,0.2);
}

/* Add some padding inside the card container */

.btr {
  border-top-right-radius: 5px !important
}

.btl {
  border-top-left-radius: 5px !important
}

.btn-dark {
  color: #fff;
  background-color: #D92429;
  border-color: #D92429
}

.btn-dark:hover {
  color: #fff;
  background-color: #D92429;
  border-color: #D92429
}

.nav-pills {
  display: table !important;
  width: 100%
}

.nav-pills .nav-link {
  border-radius: 0px;
  border-bottom: 1px solid #D92429
}

.nav-item {
  display: table-cell;
 
}

.form {
  padding: 10px;
 
}

.form input {
  margin-bottom: 12px;
  border-radius: 3px
}

.form input:focus {
  box-shadow: none;
  border-color:#D92429;
}

.form select:focus {
  box-shadow: none;
  border-color:#D92429;
}

.form button {
  margin-top: 20px
}

.nav-pills .nav-link{
  color: #D92429;
}

.nav-pills .nav-item{
    background: #e0dfdf 
}

.nav-pills li a:hover{
  color: #770f13;
}

.nav-pills li a:hover{
  color: #770f13;
}

.navbar-nav .nav-item {
  padding-right: 30px;
  font-size: 20px;
  padding-left: 30px;;
}
.navbar-dark .navbar-nav > ul > li > a:hover{
  color: #770f13;
}


.nav-pills .nav-link.active, .nav-pills .show > .nav-link{
  background: #000000;
}

a {
  text-decoration: none;
  color: #9b9999;
}
a:hover{
  color: #d1cece;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@media (max-width: 600px) {
  .boxes {
    grid-template-columns: 1fr;
  }

  .form {
    width: 90%;
  }

  .ticket-created h2,
  .heading h1 {
    font-size: 2rem;
  }

  .heading p {
    font-size: 1.5rem;
  }
}

#cover-spin {
  position:fixed;
  width:100%;
  left:0;right:0;top:0;bottom:0;
  background-color: rgba(255,255,255,0.2);
  z-index:9999;
  
}

@-webkit-keyframes spin {
from {-webkit-transform:rotate(0deg);}
to {-webkit-transform:rotate(360deg);}
}

@keyframes spin {
from {transform:rotate(0deg);}
to {transform:rotate(360deg);}
}

#cover-spin::after {
  content:'';
  display:block;
  position:absolute;
  left:48%;top:40%;
  width:40px;height:40px;
  border-style:solid;
  border-color:rgb(192, 1, 1);
  border-top-color:transparent;
  border-width: 4px;
  border-radius:50%;
  -webkit-animation: spin .8s linear infinite;
  animation: spin .8s linear infinite;
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
  color: red;
}

.page-item:first-child .page-link {
  border-top-left-radius: var(--bs-pagination-border-radius);
  border-bottom-left-radius: var(--bs-pagination-border-radius);
  color: red;
}


.chats .chat-btn {
  position: fixed;
  right: 14px;
  bottom: 30px;
  cursor: pointer
}

.chats .chat-btn .close {
  display: none
}

.chats .chat-btn i {
  transition: all 0.9s ease
}

.chats #check:checked .chat-btn i {
  display: block;
  pointer-events: auto;
  transform: rotate(180deg)
}

.chats #check:checked~.chat-btn .comment {
  display: none
}

.chats .chat-btn i {
  font-size: 22px;
  color: #fff !important
}

.chats .chat-btn {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background-color: rgb(235, 51, 51);
  color: #fff;
  font-size: 22px;
  border: none
}

.chats .wrapper {
  position: fixed;
  bottom: 100px;
 width: 500px;
  height: 635px;
  background-color: #fff;
  border-radius: 5px;
  opacity: 0;
  transition: all 0.4s;
  border: solid rgb(235, 51, 51);
}

.chats #check:checked~.wrapper {
  opacity: 1
}

.chats .header {
  padding: 13px;
  background-color: rgb(235, 51, 51);
  border-radius: 5px 5px 0px 0px;
  margin-bottom: 10px;
  color: #fff
}

.chats .chat-form {
  padding: 15px
}

.chats .chat-form input,
textarea,
button {
  margin-bottom: 10px
}

.chats .chat-form textarea {
  resize: none
}

.chats .form-control:focus,
.btn:focus {
  box-shadow: none
}

.chats .btn,
.btn:focus,
.btn:hover {
  background-color: rgb(235, 51, 51);
  border: rgb(235, 51, 51)
}

.chats #check {
  display: none !important
}

.chats .chat-body{
  height: calc(600px - (45px + 70px));
  position: relative;
}

.chats .chat-body .message-container {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
}
.chats .chat-body .message-container::-webkit-scrollbar {
  display: none;
}

.chats .chat-footer {
  height: 40px;
  border: 1px solid #263238;
  display: flex;
}

.chats  .chat-footer input {
  height: 100%;
  flex: 85%;
  border: 0;
  padding: 0 0.7em;
  font-size: 1em;
  border-right: 1px dotted #607d8b;
  font-family: "Open Sans", sans-serif;
}

.chats .chat-footer button {
  border: 0;
  display: grid;
  place-items: center;
  cursor: pointer;
  flex: 15%;
  height: 100%;
  background: transparent;
  outline: none;
  font-size: 15px;
  color: lightgray;
}

.chats .chat-footer button:hover {
  color: #fd2929;
}

.my-content {
  width: 50%;
}
.my-content {
  float: right;
  background: #fd2929;
  color: #FFF;
  text-align: right;
  padding: 5px;
  margin-bottom: 4px;
  font-size: 13px;
}
.my-content{
  float: right;
}
.my-content{
  display: block;
  float: right;
  width: 100%;
  text-align: right;
}



.your-content {
  width: 50%;
}
.your-content {
  float: left;
  background: #0c0000;
  color: #FFF;
  text-align: left;
  padding: 5px;
  margin-bottom: 4px;
  font-size: 13px;
}
.your-content{
  float: left;
}
.your-content{
  display: block;
  float: left;
  width: 100%;
  text-align: left;
}

.big-circle {
  width: 150px;
  height: 150px;
  border-radius: 50%;
  background-color: #fd2929; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
  font-size: 48px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}

.big-circle1 {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #d3d0d0; /* Change the background color as needed */
  color: #ffffff; /* Change the text color as needed */
  font-size: 48px;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
}